import { ENVIRONMENT_CONFIG } from './environment.const';

const CmsServerUrl = 'https://eu-cdn.contentstack.com';

export const environment = {
  production: false,
  platformUrl: 'https://public-admin-api.ppp-dev.apps.jnj',
  auth_username: '2c160deb-97b6-b7d3-ceae-f8830f08eaa2',
  auth_password: '0387478e-5a2e-7288-7f4a-28faa89c0090',
  accept_header: 'application/vnd.c4t.public-admin-api.v1+json',
  content_type_header: 'application/vnd.c4t.public-admin-api.v1+json',

  cmsUrl: CmsServerUrl,
  cmsApiKey: 'blt2043dcd74f319d2f',
  cmsAccessToken: 'csd6cb2dae29d3bfcf8c6cc9df',
  cmsEnvironment: 'dev',

  // Options
  refreshEvaluation: true,
  dev_remember_login_email: true,
  app_version: '1.0.0',

  environments: ENVIRONMENT_CONFIG,
};
